import {HttpClient} from "./HttpClient";
import { FAQObjectModel } from "../models/FAQObjectModel"
import { IFAQRepository } from "../respository/IFAQRepository";

export class FAQResponsesAPI extends HttpClient implements IFAQRepository {
    private static readonly DefaultBaseUrl = "https://cyborgmob.com/wp-json/wp/v2"
    private static classInstance?: FAQResponsesAPI;

    private constructor(baseUrl: string) {
        super(baseUrl);
    }

    public static getInstance() {
        if (!this.classInstance && process.env.REACT_APP_ENV_WORDPRESS_API_BASE_URL) {
            this.classInstance = new FAQResponsesAPI(process.env.REACT_APP_ENV_WORDPRESS_API_BASE_URL);
        } else {
            this.classInstance = new FAQResponsesAPI(this.DefaultBaseUrl);
        }
        return this.classInstance;
    }

    public getFAQ = () => this.instance.get<FAQObjectModel[]>('/faqs/?_fields=acf,id,slug&filter[orderby]=menu_order&order=asc');
}