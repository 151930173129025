import { Component } from 'react'
import linkedin_icon from "../assets/footer/icon_brand-LI.svg"
import twitter_icon from "../assets/footer/icon_brand-TW.svg"
import facebook_icon from "../assets/footer/icon_brand-FB.svg"
import './AwardeeShare.css'

interface AwardeeShareProps {
    pageLink?: string | null
}

const shareDescription = "The %23JusticeRisingAwards honor and celebrate the visionary Black leaders who play a critical role in the fight for racial justice and equality in the U.S."

export class AwardeeShare<AwardeeShareProps> extends Component {
    render() {
        return(
            <div id="awardee-share-container">
                <h4 className="header-s" id="awardee-share-header">
                    Share
                </h4>
                <div id="awardee-share-container-social-media-icon-links">
                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(window.location.href)} 
                        target="_blank" 
                        rel="noreferrer"
                        aria-label="Share this page on Facebook">
                        <img id="awardee-share-social-media-icon" src={facebook_icon} alt="facebook icon"/>
                    </a>
                    <a href={"https://twitter.com/share?url="+ encodeURIComponent(window.location.href)+"&text=" + shareDescription} 
                        aria-label="Share this page on Twitter">
                        <img id="awardee-share-social-media-icon" src={twitter_icon} alt="twitter icon"/>
                    </a>
                    <a href={"https://www.linkedin.com/share/?url=" + encodeURIComponent(window.location.href)} 
                         aria-label="Share this page on LinkedIn">
                        <img id="awardee-share-social-media-icon" src={linkedin_icon} alt="linkedin icon"/>
                    </a>
                </div>
            </div>
        )
    }
}