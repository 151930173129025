import React from 'react'
import './FrequentlyAskedQuestionsPage.css'
import arrowUp from "../assets/Vector-Arrow-Up.svg"
import arrowDown from "../assets/Vector-Arrow-Down.svg"
import { FAQResponsesAPI } from '../api/FAQResponsesAPI'
import { FAQObjectModel } from '../models/FAQObjectModel'
import { ContactInfoCard } from '../components/ContactInfoCard'
import { Helmet, HelmetProvider } from "react-helmet-async";

export function FrequentlyAskedQuestionsPage() {
    const [faqs, setFaqs] = React.useState<FAQObjectModel[]>([])
    // eslint-disable-next-line
    const [isLoaded, setIsLoaded] = React.useState(false)
    // eslint-disable-next-line
    const [error, setError] = React.useState<any>(null)

    React.useEffect(() => {
        const faqApi = FAQResponsesAPI.getInstance();
        faqApi.getFAQ()
            .then((result) => {
                setFaqs(result.data)
                setIsLoaded(true)
            },
            (error) => {
                console.log(error);
                setIsLoaded(true)
                setError(error)
            })
    }, [])
    
    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Justice Rising Awards - FAQ</title>
                    <meta itemProp="name" content="Justice Rising Awards - FAQ"/>
                    <meta itemProp="description" content="Open Society Foundations Justice Rising Awards Frequently asked questions"/>
                    <meta itemProp="image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Justice Rising Awards - FAQ"/>
                    <meta property="og:description" content="Open Society Foundations Justice Rising Awards Frequently asked questions"/>
                    <meta property="og:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="Justice Rising Awards - FAQ"/>
                    <meta name="twitter:description" content="Open Society Foundations Justice Rising Awards Frequently asked questions"/>
                    <meta name="twitter:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                </Helmet>
            </HelmetProvider>
            <div id="frequently-asked-questions-page-container" className="container">
                <div className="header-xl" id="faq-page-header-title">
                    Frequently asked questions
                </div>
                <hr className="support-page-header-horizontal-rule"/>
                <div id="faq-contents-container">
                    {faqs.map(faq => {
                        return <CreateFAQSection question={faq.acf.question} answer={faq.acf.answer} key={faq.id}/>
                    })}
                </div>
                <div id="contact-section">
                    <ContactInfoCard/>
                </div>
            </div>
        </>
    )
}

function CreateFAQSection(props: {question: string, answer: string}) {
    const [open, setOpen] = React.useState(false);

    const downArrow = 
        <div 
            style={{display: open ? "none" : "inline"}}
        >
            <img src={arrowDown} alt="" style={{minHeight: "auto", minWidth: "1rem"}}/>
        </div>

    const upArrow = 
        <div 
            style={{display: !open ? "none" : "inline"}}

        >
            <img src={arrowUp} alt="" style={{minHeight: "auto", minWidth: "1rem"}}/>
        </div>

    const contentStateClass = open ? "faq-section-contents-show" : "faq-section-contents-hide"
    const sectionQuestionClass = open ? "faq-section-question-show" : "faq-section-question"
    const sectionAnswerClass = open ? "faq-section-answer-show" : "faq-section-answer-hide"
    return(
        <div>
            <div className={contentStateClass}>
                <div 
                    className={`faq-question ${sectionQuestionClass}`}
                    onClick={() => {setOpen(!open);}}>
                        {props.question}
                    <div id="faq-section-arrow">
                        {downArrow}
                        {upArrow}
                    </div>
                </div>
                <div className={`body-text ${sectionAnswerClass}`}>
                    {props.answer}
                </div>
            </div>
            <div id="faq-section-border"/>
        </div>
    )
}