import React, { Component, MouseEvent } from "react"
import "./Footer.css"
import cc_icon_first from "../assets/footer/icon_cc-1.svg"
import cc_icon_second from "../assets/footer/icon_cc-2.svg"
import cc_icon_third from "../assets/footer/icon_cc-3.svg"
import cc_icon_fourth from "../assets/footer/icon_cc-4.svg"
import linkedin_icon from "../assets/footer/icon_brand-LI.svg"
import youtube_icon from "../assets/footer/icon_brand-YT.svg"
import twitter_icon from "../assets/footer/icon_brand-TW.svg"
import facebook_icon from "../assets/footer/icon_brand-FB.svg"
import instagram_icon from "../assets/footer/icon_brand-IG.svg"
import osf_footer_logo from "../assets/footer/osf-footer-logo.svg"
import { GetInTouch } from "../pages/GetInTouch"
import { PathNames } from "../PathNames"


export class Footer extends Component {
    render() {
        return (
            <section id="footer">
                <div id="contact-anchor" className="section-anchor"/>
                <div className="container--wider">
                    <div id="footer__container--upper" className="support-text-l">
                        <div id="footer__container--logo-site-links">
                            <img id="footer__logo" src={osf_footer_logo} alt=""/>
                            <div>
                                <div id="footer__container--site-links">
                                    <button className="button-link" data-anchor-name="about" onClick={this.scrollToHandler}>
                                        About the Awards</button>
                                    <button className="button-link" data-anchor-name="awardees" onClick={this.scrollToHandler}>
                                        Awardees</button>
                                    <a href="/faq">Frequently Asked Questions</a>
                                </div>
                                <div id="footer__container--social-media-icon-links">
                                    <a href="https://www.facebook.com/OpenSocietyFoundations" aria-label="Visit our Facebook page">
                                        <img id="footer-social-media-icon" src={facebook_icon} alt="facebook icon"/>
                                    </a>
                                    <a href="https://twitter.com/opensociety" aria-label="Visit our Twitter page">
                                        <img id="footer-social-media-icon" src={twitter_icon} alt="twitter icon"/>
                                    </a>
                                    <a href="https://www.instagram.com/opensocietyfoundations/" aria-label="Visit our Instagram page">
                                        <img id="footer-social-media-icon" src={instagram_icon} alt="instagram icon"/>
                                    </a>
                                    <a href="https://www.youtube.com/c/opensociety" aria-label="Visit our Youtube page">
                                        <img id="footer-social-media-icon" src={youtube_icon} alt="youtube icon"/>
                                    </a>
                                    <a href="https://www.linkedin.com/company/open-society-foundations" aria-label="Visit our LinkedIn page">
                                        <img id="footer-social-media-icon" src={linkedin_icon} alt="linkedin icon"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="footer__get-in-touch">
                            <GetInTouch/>
                        </div>
                    </div>
                    <hr id="footer__horizontal-rule"/>
                    <div id="footer__container--lower" className="support-text-m">
                        <div id="footer__container--commons-copyright">
                            <div id="ccWrapper">
                                <a href={PathNames.IntellectualProperty}>
                                    <div id="footer__container--creative-commons-icons">
                                        <img src={cc_icon_first} alt="creative commons icon"/>
                                        <img src={cc_icon_second} alt="creative commons attribution icon"/>
                                        <img src={cc_icon_third} alt="creative commons non-commercial icon"/>
                                        <img src={cc_icon_fourth} alt="creative commons no derivative works icon"/>
                                    </div>
                                </a>
                            </div>
                            <div id="footer__container--copyright">
                                &copy; {(new Date().getFullYear())} Open Society Foundations, some rights reserved.
                            </div>
                        </div>
                        <div id="footer__container--compliance-site-links">
                            <a href={PathNames.TermsAndConditions}>Terms and Conditions</a>
                            <a href={PathNames.PrivacyPolicy}>Privacy</a>
                            <a href={PathNames.Accessibility}>Accessibility</a>
                            <a href={PathNames.CookiePolicy}>Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    scrollToHandler = (event: MouseEvent<HTMLButtonElement>) => {
        const anchorName = event.currentTarget.getAttribute("data-anchor-name");
        if (anchorName && document.getElementById('home-page-container')) {
            const element = document.getElementById(anchorName);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
            }
        } else {
            window.location.href = "/#" + anchorName
        }
    }
}