import { Component } from 'react'
import { ContactInfoCard } from '../components/ContactInfoCard'
import { PathNames } from '../PathNames'
import { Helmet, HelmetProvider } from "react-helmet-async";
import './PrivacyPolicyPage.css'

export class PrivacyPolicyPage extends Component {

    render() {
        return(
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>Justice Rising Awards - Privacy Policy</title>
                        <meta itemProp="name" content="Justice Rising Awards - Privacy Policy"/>
                        <meta itemProp="description" content="Open Society Foundations Justice Rising Awards Privacy Policy"/>
                        <meta itemProp="image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta property="og:url" content={window.location.href}/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content="Justice Rising Awards - Privacy Policy"/>
                        <meta property="og:description" content="Open Society Foundations Justice Rising Awards Privacy Policy"/>
                        <meta property="og:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:title" content="Justice Rising Awards - Privacy Policy"/>
                        <meta name="twitter:description" content="Open Society Foundations Justice Rising Awards Privacy Policy"/>
                        <meta name="twitter:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                    </Helmet>
                </HelmetProvider>
                <div id="privacy-policy-page-container" className="container">
                    <div className="header-xl" id="toc-page-header-title">
                        Privacy
                    </div>
                    <hr className="support-page-header-horizontal-rule"/>
                    <div id="contents-container">
                        <div id="contact-info-container">
                            <ContactInfoCard/>
                        </div>
                        <div id="privacy-policy-text-container">
                            <div className="body-text support-page-body-text">
                                This website is owned and operated by the Open Society Foundations.
                            </div>
                            

        <div className="body-text support-page-body-text">Your privacy is important to the Open Society Foundations (<strong>OSF</strong>). This privacy policy provides information about the different types of personal information that we collect and the ways in which we use it. This privacy policy applies
            to all those who interact with us online (particularly through the website <a href="http://www.opensocietyfoundations.org">www.opensocietyfoundations.org</a> and any other website we own or operate) or whose personal information we otherwise
            collect (although please note that if you are an employee or worker at OSF, there is a separate privacy policy).</div>

        <div className="body-text support-page-body-text">If you have any questions, please feel free to contact us using the contact details included at section 18.</div>
        
        <div className="body-text support-page-body-text">
        <div className="body-text support-page-body-text">
<ol>
            <li><a href="#toc-1">Who we are</a></li>
            <li><a href="#toc-2">When do we collect personal information about you?</a></li>
            <li><a href="#toc-3">What personal information do we use?</a></li>
            <li><a href="#toc-4">What information is collected automatically online?</a></li>
            <li><a href="#toc-5">What about personal information that is considered more sensitive?</a></li>
            <li><a href="#toc-6">How we use your personal information</a></li>
            <li><a href="#toc-7">Lawful grounds</a></li>
            <li><a href="#toc-8">Use of our online services</a></li>
            <li><a href="#toc-9">Do we share your personal information?</a></li>
            <li><a href="#toc-10">International data transfers</a></li>
            <li><a href="#toc-11">Securing your personal information</a></li>
            <li><a href="#toc-12">How long do we keep your personal information?</a></li>
            <li><a href="#toc-13">Children</a></li>
            <li><a href="#toc-14">Your rights and preferences</a></li>
            <li><a href="#toc-15">Other websites</a></li>
            <li><a href="#toc-16">Social media</a></li>
            <li><a href="#toc-17">Updating this privacy policy</a></li>
            <li><a href="#toc-18">How to contact us</a></li>
        </ol></div>
        </div>

        <div id="toc-1"></div>
        <div className="header-l support-page-header-text">1. Who we are</div>

        <div className="body-text support-page-body-text">This privacy policy describes how OSF will collect and use your personal information when you interact with us online or when we otherwise collect and use your personal information. The Open Society Foundations have offices located through the
            world. You can learn more about where we are located on our <a href="https://www.opensocietyfoundations.org/who-we-are/offices">Offices page</a>. While our website is operated by OSF in the United States, the personal information we collect
            (through the website or otherwise) may be used by any of our family of offices and foundations in line with the purposes set out in this privacy policy. When we refer to OSF in this privacy policy, we include all OSF offices and foundations.</div>

        <div className="body-text support-page-body-text">Please note that there are certain aspects of this privacy policy that only apply when we are required to comply with specific laws (e.g., the European data protection law, the General Data Protection Regulation). In some instances, EU data protection
            law may apply. Please see section 10 for information about the transfer of your personal information.</div>

        <div className="body-text support-page-body-text">The representative of the Open Society Foundations’ U.S. entity that operates our websites, the Open Society Institute, in the EU is Open Society Foundation–London.</div>

        <div id="toc-2"></div>
        <div className="header-l support-page-header-text">2. When do we collect personal information about you?</div>

        <div className="body-text support-page-body-text">We may obtain your personal information from a number of sources including:</div>

        <div className="body-text support-page-body-text">
<ol type="A">
            <li>When you give it to us <strong>directly</strong>. For example, if you provide us with your email address to sign up to receive our news updates or if you provide us with your name and email address when you leave a comment on our website.</li>
            <li>When we obtain it <strong>indirectly</strong>. Your personal information may be shared with us by third parties such as recruitment agencies when we are recruiting staff and vetting agencies when we gather information on grant applicants.
                To the extent that we have not done so already and that we are required to, we will notify you when we receive personal information about you from them, and tell you how and why we intend to use that personal information.</li>
            <li>When it is <strong>publicly available</strong>. Your personal information may be available to us from external publicly available sources. This includes information available on government websites (e.g., U.S. Federal and European institutions)
                or non-OSF company or institutional websites.</li>
            <li>When you visit our <strong>website</strong>. Our website uses cookies and other tracking technologies to improve your experience of our website—this can involve collecting personal information. We will only deploy non-essential cookies, including
                analytics cookies, with your consent. Please refer to our <a href="/policies/cookie-policy">Cookie Policy</a> for details of the way the use of cookies may affect your personal information and to update your preferences.</li>
        </ol></div>

        <div id="toc-3"></div>
        <div className="header-l support-page-header-text">3. What personal information do we use?</div>

        <div className="body-text support-page-body-text">
<ul>
            <li>We may collect, store, and otherwise process the following kinds of personal information:</li>
            <li>your full name and contact details, including email address, postal address, and telephone number;</li>
            <li>information you provide in connection with your application for a grant, fellowship, or scholarship which may include date of birth, biographical information, education and work experience, information about family members, employment information,
                citizenship and tax residency information, passport, dietary information, images of your signature, expenses, amount and intended purpose of grants you are involved in, and information about your participation in a specific program;</li>
            <li>information you provide when you are considered for a board role which may include education and work experience, information about family members, employment information, potential conflicts of interest, and expenses;</li>
            <li>public professional status and role of political members in the context of advocacy;</li>
            <li>information you provide when you are considered as a contractor which may include education and work experience, employment information, images of your signature, and information about the services you may provide to OSF;</li>
            <li>information about your use of our information and communications systems;</li>
            <li>financial information including bank details, payment card details;</li>
            <li>your communication preferences;</li>
            <li>information about your professional status and role; and</li>
            <li>information you provide to us through comments or Forums (see below).</li>
        </ul></div>

        <div id="toc-4"></div>
        <div className="header-l support-page-header-text">4. What information do we collect online?</div>

        <div className="body-text support-page-body-text">We will also collect certain information when you interact with our website. We do this through server log files, cookies, and pixel tags. While the information obtained may not be personal information under the laws of the country you are based
            in, we recognize that there are certain laws (e.g., EU law) where this information is likely to be personal information.</div>

        <div className="body-text support-page-body-text">For instance, we identify and log the Internet Protocol (IP) address of the device you use to access our website automatically in our server log files whenever you visit our website, along with the time of your visit and pages you visit. While
            the intention of such a practice is to calculate website usage levels and to help us diagnose problems with the website’s servers, we recognize that an IP address is also an online identifier which can be used to create profiles of individuals
            behind devices. Thus, if we combine non-personal information with personal information, we will treat the combined information as personal information as long as it is combined.</div>

        <div className="body-text support-page-body-text">We will only deploy non-essential cookies, including analytics cookies, with your consent. Please see our <a href="/policies/cookie-policy">Cookie Policy</a> for further information about how we collect and use cookies and pixels and our use of
            analytics.
        </div>

        <div id="toc-5"></div>
        <div className="header-l support-page-header-text">5. What about personal information that is considered more sensitive?</div>

        <div className="body-text support-page-body-text">Certain countries have laws that recognize particular types of personal information as more sensitive and therefore requiring greater protection. We collect these types of personal information in limited circumstances. For instance, we may collect
            personal information about health, religion, ethnicity, sexual life, and political opinions. We may also collect personal information about criminal convictions and offenses.</div>

        <div className="body-text support-page-body-text">We will only collect and use this sensitive information if there is a valid reason for doing so and where the law allows us to. For instance, there are stricter rules about the collection and use of sensitive information where EU law applies.
            When we collect sensitive information or criminal conviction data about you and we are required to tell you what our lawful basis is for doing so, we will set this out.</div>

        <div id="toc-6"></div>
        <div className="header-l support-page-header-text">6. How we use your personal information</div>

        <div className="body-text support-page-body-text">OSF will use your personal information:</div>

        <div className="body-text support-page-body-text">
<ul>
            <li>to fulfill your requests to submit an application or register on the website;</li>
            <li>to understand and measure your interaction with the website and online content;</li>
            <li>to allow us to review, evaluate and administer grants, scholarships, and other OSF programs;</li>
            <li>to enable us to monitor progress and completion of those programs;</li>
            <li>to further our programmatic work on crimes against humanity, human rights abuses, international and social justice;</li>
            <li>to enable us to monitor and manage conflicts of interest;</li>
            <li>to enable us to manage governance transactions;</li>
            <li>to contact you if you are involved in a project we are administering;</li>
            <li>to contact you to make recommendations, proposals, or notices to you about opportunities you may potentially be interested in;</li>
            <li>to make administrative and marketing communications;</li>
            <li>to enable you to use our online services (e.g., our comments, newsletter, subscription);</li>
            <li>to administer any financial transaction between us;</li>
            <li>to register you for an event and any administration associated with the event;</li>
            <li>to cover or reimburse expenses or travel related to business;</li>
            <li>to contact you for our advocacy purposes and to inform you of events or publications we think you may potentially be interested in;</li>
            <li>for research purposes so that we can identify key trends of findings in connection with our programs and projects;</li>
            <li>for research and record keeping purposes in connection to carrying out advocacy work through our programs and projects;</li>
            <li>to satisfy legal obligations which are binding on us;</li>
            <li>for the prevention of fraud or misuse of service; and</li>
            <li>for the establishment of, defense against, or enforcement of legal claims.</li>
        </ul></div>

        <div id="toc-7"></div>
        <div className="header-l support-page-header-text">7. Lawful grounds</div>

        <div className="body-text support-page-body-text">Under certain laws, we are required to rely on one or more lawful grounds to collect and use the personal information that we have outlined above. We consider the grounds listed below to be relevant:</div>

        <div className="body-text support-page-body-text">
<ol type="A">
            <li><strong>Legal obligation</strong>. Where the processing of your personal information is necessary for us to comply with a legal obligation to which we are subject (e.g., because we have to provide information to tax authorities).</li>
            <li><strong>Contractual relationship</strong>. Where it is necessary for us to use your personal information in order to perform a contract to which you are a party (or to take steps at your request prior to entering a contract).</li>
            <li><strong>Legitimate interests</strong>. We rely on this basis where applicable law allows us to collect and use personal information for our legitimate interests and the use of your personal information is fair, balanced, and does not unduly
                impact your rights. For instance, it is in our legitimate interest to use personal information in association with planning of, delivery of, and monitoring of our programmatic work and grant making.</li>
            <li><strong>Consent</strong>. We may obtain your consent to use your personal information in certain circumstances (e.g., to send you email marketing or for the use of non-essential cookies). We will ensure that when we obtain your consent, you
                are free both to give it and to decline to give it. Additionally, you may always withdraw your consent at any time without any further detriment to you.</li>
        </ol></div>

        <div id="toc-8"></div>
        <div className="header-l support-page-header-text">8. Use of our online services (e.g., forums, blogs, comments)</div>

        <div className="body-text support-page-body-text">We may make available certain interactive services through our website such as message boards, blogs, comment section and wikis (<strong>“Forums”</strong>). You will be able to post comments, information and materials to Forums. Please see our
            <a href="https://www.opensocietyfoundations.org/policies/community-guidelines">Community Guidelines</a>.</div>

        <div className="body-text support-page-body-text">Any information (including personal information) that you post in a Forum becomes public information and may be available to other visitors to our website and to the general public. Apart from posting directly in a Forum, you will also be able
            to share your comments via email, which then may be published on sections of our website where we post comments. We reserve the right to only publish selected comments, and we may edit them before publishing for brevity, style, grammar or
            clarity. In addition, your name will be available in association with any posting you make. Additionally, information regarding your Forum activities is also available for other users to view.</div>

        <div className="body-text support-page-body-text">Please exercise caution and discretion when posting information (including personal information) in a Forum. In particular, please do not post personal information about other individuals unless you are confident that they would not object to
            their personal information being used in this way. If you post any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this privacy policy.
            <strong>OSF IS NOT RESPONSIBLE FOR THE USE BY OTHERS OF THE PERSONAL INFORMATION THAT YOU DISCLOSE IN FORUMS</strong>.</div>

        <div className="body-text support-page-body-text">If you are a resident of California, under 18, and a registered user of the services, you may ask us to remove content or information that you have posted to the services by contacting us using the details at section 18. Please note that your
            request does not ensure complete or comprehensive removal of the content or information, as, for example, some of your content may have been reposted by another user.</div>

        <div id="toc-9"></div>
        <div className="header-l support-page-header-text">9. Do we share your personal information?</div>

        <div className="body-text support-page-body-text">Personal information collected by OSF (through the website or otherwise) may be shared between any of our family of offices and foundations in line with the purposes set out in this privacy policy in section 6 and in accordance with the international
            data sharing agreements between OSF offices.</div>

        <div className="body-text support-page-body-text">We may also share your personal data with organizations we partner with in pursuit of the same programmatic objectives as set out in section 6 of this privacy policy.</div>

        <div className="body-text support-page-body-text">Apart from where set out in this privacy policy, OSF will not sell, rent, or lease your personal information to other third parties. However, we may ask third-party processors to assist us or we may disclose your personal information to selected
            third-party processors (such as agents or sub-contractors) for the purposes outlined at section 6. The third party in question will be obligated to use any personal information they receive in accordance with our instructions.</div>

        <div className="body-text support-page-body-text">For instance, we use a third-party marketing automation platform to assist with managing email addresses and sending emails to our subscribers. When you sign up to receive email updates from us and provide us with your email address, you will
            receive an automated email originating from this third party confirming your subscription and providing you with a link to unsubscribe from the list.</div>

        <div className="body-text support-page-body-text">We may also engage third parties to assist us with website hosting, data analysis, transaction processing, grant making, and other administrative services.</div>

        <div className="body-text support-page-body-text">We reserve the right to disclose your personal information to third parties:</div>

        <div className="body-text support-page-body-text">
<ul>
            <li>in the event that we buy or sell any business or assets, in which case we will disclose your personal information to the prospective buyer or seller or such business or assets;</li>
            <li>if substantially all of our assets are acquired by a third party, personal information held by us may be one of the transferred assets;</li>
            <li>for auditing purposes;</li>
            <li>to enforce our Terms &amp; Conditions;</li>
            <li>to protect any OSF operations including the rights, safety, and property of OSF and our staff;</li>
            <li>if we are under any legal or regulatory obligation to do so (e.g., to comply with legal process or requests from government authorities);</li>
            <li>to permit us to pursue available remedies or limit the damages that we may sustain; and</li>
            <li>in connection with any legal proceedings or prospective legal proceedings, in order to establish, exercise, or defend our legal rights.</li>
        </ul></div>

        <div id="toc-10"></div>
        <div className="header-l support-page-header-text">10. International data transfers</div>

        <div className="body-text support-page-body-text">Certain countries have rules around the transfer of personal information and require us to ensure that personal information remains protected according to appropriate standards. If you are located in the United Kingdom or the EU or your personal
            data is collected and used by an organization in the United Kingdom or the EU, please note that certain countries outside of the United Kingdom or the EU have a different standard of protection for personal information. In such cases, where
            your personal information is transferred, stored, and/or otherwise processed outside the United Kingdom or EU on a regular basis, we will implement appropriate safeguards designed to protect your personal information. The full list of countries
            recognized by the European Commission as providing an adequate level of protection is available <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">here</a>.</div>

        <div className="body-text support-page-body-text">We may engage third parties outside the United Kingdom and the EU to process personal information on our behalf. Since personal information may be shared between OSF entities, personal information is transferred outside the United Kingdom and
            the EU to OSF in the United States.</div>

        <div className="body-text support-page-body-text">If you have any questions about the transfer of your personal information, please contact us using the details at section 18.</div>

        <div id="toc-11"></div>
        <div className="header-l support-page-header-text">11. Securing your personal information</div>

        <div className="body-text support-page-body-text">OSF takes reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information. We restrict access to those who have a need to know and we train staff in handling the information securely.
            Unfortunately, there is no such thing as perfect security in the online environment. As a result, we cannot and do not guarantee the security of any information you transmit to us through or in connection with the website.</div>

        <div className="body-text support-page-body-text">If you believe that your interaction with us is no longer secure (e.g., you consider that the security of any account you might have with us has been compromised) please immediately notify us by contacting us at <a href="mailto:dataprotection@opensocietyfoundations.org">dataprotection@opensocietyfoundations.org</a>.</div>

        <div id="toc-12"></div>
        <div className="header-l support-page-header-text">12. How long do we keep your personal information?</div>

        <div className="body-text support-page-body-text">We will keep your personal information for as long as necessary:</div>

        <div className="body-text support-page-body-text">
<ul>
            <li>to comply with any statutory or regulatory requirements we are subject to under applicable law;</li>
            <li>to fulfill the purposes for which the personal information was collected; or</li>
            <li>to defend our or a third party’s legal rights.</li>
            <li>If you have any questions, about the retention periods for holding your personal information, please contact us using the details at section 18 below.</li>
        </ul></div>

        <div id="toc-13"></div>
        <div className="header-l support-page-header-text">13. Children</div>

        <div className="body-text support-page-body-text">Our website is not intended to be used by individuals who are under the age of sixteen (16). If you are under the age of sixteen (16), please do not provide personal information to us or through our website.</div>

        <div id="toc-14"></div>
        <div className="header-l support-page-header-text">14. Your rights and preferences</div>

        <div className="body-text support-page-body-text">Please note that we will honor your requests to exercise your rights to the extent possible and required under applicable law. Certain of these rights may only be available to individuals who interact with our UK or EU offices and to individuals
            who are located in the United Kingdom or the EU who are on our mailing list.</div>

        <div className="body-text support-page-body-text">You have the right to:</div>

        <div className="body-text support-page-body-text">
<ul>
            <li>ask us for <strong>confirmation</strong> of what personal information we hold about you, and to request a copy of that information; if we are satisfied that you have a legal entitlement to see this personal information, and we are able to
                confirm your identity, we will provide you with this personal information;</li>
            <li><strong>withdraw your consent</strong> at any time if we have relied on consent as the lawful ground to use your personal information;</li>
            <li>request that we<strong> delete</strong> the personal information we hold about you, as far as we are legally required to do so;</li>
            <li>ask that we <strong>correct</strong> any personal information that we hold about you which you believe to be inaccurate;</li>
            <li><strong>object</strong> to the processing of your personal information where we (i) process on the basis of the legitimate interests ground; (ii) use the personal information for direct marketing; or (iii) use the personal information for
                statistical purposes;
            </li>
            <li>ask for the <strong>provision</strong> of your personal information in a machine-readable format to either yourself or a third party, provided that the personal information in question has been provided to us by you, and is being processed
                by us (i) in reliance on your consent; or (ii) because it is necessary for the performance of a contract to which you are party; and in either instance, we are processing it using automated means; and</li>
            <li>ask for processing of your personal information to be <strong>restricted</strong> if there is disagreement about its accuracy or legitimate use.</li>
        </ul></div>

        <div className="body-text support-page-body-text"><strong>Please note that </strong>you also have the right to lodge a complaint with your local data protection authority about how we use your personal information if you are located in the United Kingdom or the EU or your personal data is collected
            and used by an organization in the United Kingdom or the EU. Please always consider raising your concern with us first by contacting us using the contact details in section 18 below.</div>

        <div className="body-text support-page-body-text"><strong>Your choices regarding our use and disclosure of your personal information</strong></div>

        <div className="body-text support-page-body-text">We give you choices regarding our use and disclosure of your personal information for marketing purposes. You may opt-out from:</div>

        <div className="body-text support-page-body-text">
<ul>
            <li><strong>Receiving electronic communications from us</strong>: If you no longer want to receive marketing-related emails or text messages from us on a going-forward basis, you may opt-out by sending an email to <a href="mailto:dataprotection@opensocietyfoundations.org">dataprotection@opensocietyfoundations.org</a>                or by regular mail using the address provided at section 18. You may also opt out of our email updates by using the unsubscribe link at the bottom of these emails.</li>
        </ul></div>

        <div className="body-text support-page-body-text">We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt out of receiving marketing-related emails, text messages, or paper communications from us, we may still send you important administrative
            messages, from which you cannot opt-out.</div>

        <div id="toc-15"></div>
        <div className="header-l support-page-header-text">15. Other websites</div>

        <div className="body-text support-page-body-text">The website may contain links to third-party websites. These linked websites are not under OSF’s control and we are not responsible for the privacy practices or the contents of any such linked website, or any link contained in any linked website.
            We provide such links only as a convenience, and the inclusion of a link on the website does not imply endorsement of the linked website by OSF. If you provide any personal information through any such third-party website, your interaction
            will occur on such third-party’s website (not on our website) and the personal information you provide will be collected by, and controlled by, the privacy policy of that third party. We recommend that you familiarize yourself with the privacy
            policies and practices of any such third parties. <strong>PLEASE NOTE THAT THIS PRIVACY POLICY DOES NOT ADDRESS THE PRIVACY OR INFORMATION PRACTICES OF ANY THIRD PARTIES.</strong></div>

        <div id="toc-16"></div>
        <div className="header-l support-page-header-text">16. Social media</div>
        <div className="body-text support-page-body-text">To interact with you and provide you with more information about our work, we use multiple social media platforms (e.g., <a href="https://twitter.com/opensociety">Twitter</a>, <a href="https://www.facebook.com/OpenSocietyFoundations">Facebook</a>,
            <a href="https://www.instagram.com/opensocietyfoundations/">Instagram</a>, <a href="https://www.threads.net/@opensocietyfoundations">Threads</a>, <a href="https://www.youtube.com/c/opensociety">YouTube</a>, <a href="https://www.linkedin.com/company/open-society-foundations">LinkedIn</a>,
            and <a href="https://www.tiktok.com/@opensociety">TikTok</a>) to share posts, photos and content related to OSF and its members. We engage with you using social media platforms as a part of our legitimate interests. Our use of these platforms
            involves the use of third-party service providers who may engage in extensive data collection for their personal use. We ask you to refer to the privacy policies of each platform for more detailed information. </div>
        <div className="body-text support-page-body-text">OSF has access to the following personal data that you may make available to us:</div>
        <div className="body-text support-page-body-text">
<ul>
            <li>Personal data and identifiable information that is publicly visible by default on social media platforms or has been made available by you (including name, profile names, display pictures etc).</li>
            <li>Any comments, links or interactions that you may share with us by engaging with any official OSF social media accounts through comments, chats or reactions.</li>
            <li>Any kind of statistical or performance data collected through these social media accounts to enhance and customise our content to suit your needs.</li>
        </ul></div>
        <div className="body-text support-page-body-text">Our processing of your data using social media platforms is kept to a minimum. Apart from the limited processing described below, we do not collect or store your information or any other purposes. We process your data to:</div>
        <div className="body-text support-page-body-text">
<ul>
            <li>Interact with you and enhance our social media presence.</li>
            <li>To provide more information about the work of OSF and share upcoming projects/activities; and</li>
            <li>To collect statistical data to adjust our content based on public engagement of our posts.</li>
        </ul></div>

        <div className="body-text support-page-body-text">Any information that you choose to submit to us via these platforms is done at your own risk without any expectation of privacy. Social media platforms may host external links and other users and we cannot control the actions of such users or
            the platforms themselves. Such platforms may also engage in additional plug-ins, cookies or data collection which is governed by their internal privacy policies. We encourage you to be aware of your digital rights before interacting with us
            on social media. </div>

        <div id="toc-17"></div>
        <div className="header-l support-page-header-text">17. Updating this privacy policy</div>

        <div className="body-text support-page-body-text">OSF may update this privacy policy by posting a new version on our website. If we update this privacy policy in a way that significantly changes how we use your personal information, we will bring these changes to your attention. Otherwise, we
            would recommend that you periodically review this privacy policy to be aware of any other revisions.</div>

        <div id="toc-18"></div>
        <div className="header-l support-page-header-text">18. How to contact us</div>

        <div className="body-text support-page-body-text">OSF’s Office of the General Counsel is responsible for monitoring compliance with relevant legislation in relation to personal information. You can contact the Office of the General Counsel if you have any questions about this privacy or our treatment
            of your personal information:</div>

        <div className="body-text support-page-body-text"><strong>Email:</strong> <a href="mailto:dataprotection@opensocietyfoundations.org">dataprotection@opensocietyfoundations.org</a></div>

        <div className="body-text support-page-body-text"><strong>Post:</strong> Office of the General Counsel, Open Society Foundations, 224 West 57 St, New York, NY 10019</div>

                            <div id="privacy-policy-page-footer-border"/>
                        </div>      
                    </div>
                </div>
            </>
        )
    }
}