import { Component } from 'react'
import { FinalistApi } from '../api/FinalistApi'
import { HeroApi } from '../api/HeroApi'
import { MediaDetails } from '../models/FinalistMediaModel'
import { HeroModel } from '../models/HeroModel'
import { About } from './About'
import { Finalists } from './Finalists'
import { HeroVideo } from './HeroVideo'
import { HeroFooter } from './HeroFooter'
import { HowAwardsGivenAndNewsletter } from './HowAwardsGivenAndNewsletter'
import { Helmet, HelmetProvider } from "react-helmet-async";

interface HeroProps {}
interface HeroState {
    error: null | Error;
    heroes: HeroModel[];
    mediaDetails: undefined | MediaDetails,
    isHeroesLoaded: boolean,
    isMediaDetailsLoaded: boolean
}

export class HomePage extends Component<HeroProps, HeroState> {
    constructor(props: HeroProps) {
        super(props);
        this.state = {
            error: null,
            heroes: [],
            mediaDetails: undefined,
            isHeroesLoaded: false,
            isMediaDetailsLoaded: false,
        }
    }

    componentDidMount() {
        const heroApi = HeroApi.getInstance();
        heroApi.getHeros()
        .then((result) => {
            this.setState({
                heroes: result.data.sort((a, b) => a.id - b.id),
                isHeroesLoaded: true
            });
            return result.data[0].acf.image;
        },
        (error) => {
            console.log(error);
            this.setState({
                isHeroesLoaded: true,
                error
            });
        })
        .then((imageId) => {
            if (imageId) {
                this.setHeroMedia(imageId);
            }
        },
        (error) => {
            console.log(error);
        });
    }

    setHeroMedia = async (imageId: number) => {
        const finalistApi = FinalistApi.getInstance();
        await finalistApi.getFinalistMedia()
        .then((result) => {
            let finalistMediaModel = result.data.find(m => m.id === imageId);
            if (finalistMediaModel) {
                this.setState({
                    mediaDetails: finalistMediaModel.mediaDetails,
                    isMediaDetailsLoaded: true
                });
            }
        },
        (error) => {
            console.log(error);
        });
    }

    render() {
        return(
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>Open Society Justice Rising Awards</title>

                        <meta name="description" content="The Open Society Justice Rising Awards seek to recognize and support individuals who play a critical role in the fight for racial justice." />

                        <meta itemProp="name" content="Open Society Justice Rising Awards" />
                        <meta itemProp="description" content="The Open Society Justice Rising Awards seek to recognize and support individuals who play a critical role in the fight for racial justice." />
                        <meta itemProp="image" content={this.state.mediaDetails?.sizes.full.sourceUrl}/>

                        <meta property="og:url" content={window.location.href}/>
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Open Society Justice Rising Awards" />
                        <meta property="og:description" content="The Open Society Justice Rising Awards seek to recognize and support individuals who play a critical role in the fight for racial justice." />
                        <meta property="og:image" content={this.state.mediaDetails?.sizes.full.sourceUrl}/>

                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:title" content="Open Society Justice Rising Awards" />
                        <meta name="twitter:description" content="The Open Society Justice Rising Awards seek to recognize and support individuals who play a critical role in the fight for racial justice." />
                        <meta name="twitter:image" content={this.state.mediaDetails?.sizes.full.sourceUrl}/>
                    </Helmet>
                </HelmetProvider>
                <div id="home-page-container">
                    {(!this.state.isHeroesLoaded || !this.state.isMediaDetailsLoaded) &&
                        <div className="loading"/>
                    }
                    {this.state.error &&
                        <></>
                    }
                    <div>
                        {this.state.isHeroesLoaded && this.state.isMediaDetailsLoaded && !this.state.error &&
                            <HeroVideo 
                                enabled={this.state.heroes[0].acf.enabled}
                                url={this.state.heroes[0].acf.video} 
                                thumbnailUrl={this.state.mediaDetails?.sizes.full.sourceUrl}
                                containerClass="hero-container"/>
                        }
                        <HeroFooter/>
                    </div>
                    <About/>
                    <Finalists/>
                    <HowAwardsGivenAndNewsletter/>
                </div>
            </>
        )
    }
}