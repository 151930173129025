import { Component } from "react";
import { FinalistModel } from "../models/FinalistModel";
import moreArrow from "../assets/more-arrow.svg"
import { FinalistMediaModel } from "../models/FinalistMediaModel";
import sanitizeHtml from "sanitize-html";

import './FinalistCard.css'

interface FinalistCardProps {
    key: number;
    finalist: FinalistModel;
    finalistMedia: undefined | FinalistMediaModel;
}
interface FinalistCardState {
    isMobileView: boolean
}

const MOBILE_SCREEN_WIDTH = 768

export class FinalistCard extends Component<FinalistCardProps, FinalistCardState> {

    state: FinalistCardState = {
        isMobileView: window.innerWidth <= MOBILE_SCREEN_WIDTH ? true : false,
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkWindowChange)
    }

    render() {
        const finalist = this.props.finalist;
        const thumbnailImageUrl=this.props.finalistMedia?.mediaDetails.sizes.thumbnail.sourceUrl;
        const mediumImageUrl=this.props.finalistMedia?.mediaDetails.sizes.mediumLarge.sourceUrl;
        const imageAltText=this.props.finalistMedia?.altText
        let cleanTooltip = undefined;
        if (this.props.finalistMedia?.caption.rendered) {
            cleanTooltip = sanitizeHtml(this.props.finalistMedia.caption.rendered, {
                    allowedTags: [],
                    allowedAttributes: {}
                });
        }
        return(
            <a className="undecorated-link" href={`awardee/${finalist.slug}`}>
                <div className="finalist-card">
                    <div id={finalist.slug} className="section-anchor"/>
                    <div className="finalist-card__image-container" title={cleanTooltip}>
                        {this.state.isMobileView &&
                            <img  src={thumbnailImageUrl} 
                            alt={imageAltText} 
                            title={finalist.slug}
                            />
                        }
                        {!this.state.isMobileView &&
                            <img  src={mediumImageUrl} 
                            alt={imageAltText}
                            width="332" height="189"/>
                        }
                    </div>
                    <div className="finalist-card__text-container">
                        <div>
                            <div className="card-title">{finalist.acf.name}</div>
                            <div className="header-s finalist-card__text-location">{finalist.acf.location}</div>
                            <div className="support-text-m finalist-card__text-title">
                                {finalist.acf.title}
                            </div>
                        </div>
                        <div className="support-text-m finalist-card__more-info-link-container">
                            <div>More</div>
                            <img className="finalist-card__more-arrow" src={moreArrow} alt=""/>    
                        </div>
                    </div>
                    <div className="clear"/>
                </div>
            </a>
        )
    }

    checkWindowChange = () => {
        if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
            this.setState({ isMobileView: true })
        } else {
            this.setState({ isMobileView: false })
        }
    }

    private truncateText(text: string, max: number) {
        return text.length < max ? text : `${text.substring(0, text.substring(0, max - " ...".length).lastIndexOf(' '))}${" ..."}`;
    }
}
