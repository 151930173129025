import React, { Component } from "react"
import { HowAwardsGiven } from './HowAwardsGiven'
import { Newsletter } from './Newsletter'

import "./HowAwardsGivenAndNewsletter.css"

export class HowAwardsGivenAndNewsletter extends Component {
    render() {
        return (
            <section id="how-awards-given-newsletter">
                <div className="container">
                    <HowAwardsGiven/>
                    <Newsletter/>
                    <div className="clear"/>
                </div>
            </section>
        )
    }
}
