import { Routes, Route } from "react-router-dom";
import { SuperHeader } from './components/SuperHeader'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { PathNames } from './PathNames';
import { HomePage } from './pages/HomePage';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import { FrequentlyAskedQuestionsPage } from './pages/FrequentlyAskedQuestionsPage';
import { FinalistInfo } from './components/FinalistInfo';
import { FinalistInfoPage } from "./pages/FinalistInfoPage";

import './App.css';
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { AccessibilityPage } from "./pages/AccessibilityPage";
import { CookiePolicyPage } from "./pages/CookiePolicyPage";
import { IntellectualPropertyPage } from "./pages/IntellectualPropertyPage";
import './components/cookie_consent/cookieconsent.js'
import CookieConsent from "./components/cookie_consent/CookieConsentWrapper.js";

function App() {
  return (
    <div id="main">
      <SuperHeader/>
      <Header/>
      <div id="content">
        <Routes>
          <Route
            path={PathNames.Home}
            element={<HomePage/>}
          />
          <Route
            path={PathNames.Finalists}
            element={<FinalistInfoPage/>}
          >
            <Route
              path={":finalistName"}
              element={<FinalistInfo />}
            />
          </Route>
          <Route
            path={PathNames.TermsAndConditions}
            element={<TermsAndConditionsPage/>}
          />
          <Route
            path={PathNames.PrivacyPolicy}
            element={<PrivacyPolicyPage/>}
          />
          <Route
            path={PathNames.Accessibility}
            element={<AccessibilityPage/>}
          />
          <Route
            path={PathNames.CookiePolicy}
            element={<CookiePolicyPage/>}
          />
          <Route
            path={PathNames.IntellectualProperty}
            element={<IntellectualPropertyPage/>}
          />
          <Route
            path={PathNames.FrequentlyAskedQuestions}
            element={<FrequentlyAskedQuestionsPage/>}
          />
          <Route
            path={PathNames.Wildcard}
            element={<HomePage/>}
          />
        </Routes>
        <Footer/>
        <CookieConsent/>
      </div>
   </div>
  );
}

export default App;
