import React, { Component } from "react"
import { NewsletterApi } from "../api/NewsletterAPI";
import "./Newsletter.css"

interface NewsletterProps {
}

export class Newsletter extends Component<NewsletterProps, any> {
    constructor(props: NewsletterProps) {
        super(props);
        this.state = {
            value: '',
            success: false
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event: { target: { value: any; }; }) {
        this.setState({value: event.target.value});
    }
    
    handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();
        const newsletterApi = NewsletterApi.getInstance();
        const form = new FormData();
        form.append("email", this.state.value);
        newsletterApi.subscribe(form)
        .then(result => {
            if (result.data.msg === "subscribed") {
                this.setState({success: true});
            } else {
                this.setState({success: false});
            }
        }, (error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <div id="newsletter__wrapper">
                <div id="newsletter-anchor" className="section-anchor"></div>
                <h2 id="newsletter__header" className="header-l">
                    Stay in Touch
                </h2>
                {this.state.success &&
                    <div id="newsletter-confirmation-text" className="support-text-m">
                        Thank you for subscribing. We will send you a confirmation email shortly.
                    </div>
                }
                
                {!this.state.success &&
                    <form onSubmit={this.handleSubmit}>
                        <input id="newsletter-signup-email-field" className="support-text-m" type="email" placeholder="Email Address" name="email" value={this.state.value} onChange={this.handleChange} style={{color: "black"}}required/>
                        <input id="newsletter-submit-button" className="support-text-m" type="submit" value="Submit"/>
                    </form>
                }
                <p id="newsletter-privacy-text" className="support-text-s">
                    By submitting your email address, you agree to receive updates from the Open Society Foundations about our work. To learn more about how we use and protect your personal data, please view our <a href="/privacy">privacy policy</a>.
                </p>
            </div>
        )
    }
}