import React, { Component } from "react";
import ReactPlayer from "react-player";
import "./HeroVideo.css";

interface VideoProps {
    enabled: boolean;
    thumbnailUrl: undefined | string;
    url?: string;
    containerClass: string;
}

interface VideoState {}

export class HeroVideo extends Component<VideoProps, VideoState> {
    render() {
        return (
            <section id="hero-video">
                <div className={this.props.containerClass}>
                    <div id="hero-video-wrapper">
                        {this.props.enabled &&
                            <ReactPlayer
                                url={this.props.url}
                                className="react-player"
                                playing={true}
                                width="100%"
                                height="100%"
                                controls={true}
                                playIcon={<div className="react-player__play-icon"/>}
                                light={this.props.thumbnailUrl}
                            />
                        }
                        {!this.props.enabled &&
                            <div className="react-player">
                                <img src={this.props.thumbnailUrl} alt="hero"/>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}