import { Component } from 'react'
import { ContactInfoCard } from '../components/ContactInfoCard'
import { Helmet, HelmetProvider } from "react-helmet-async";
import './AccessibilityPage.css'

export class AccessibilityPage extends Component {

    render() {
        return(
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>Justice Rising Awards - Accessibility</title>
                        <meta itemProp="name" content="Justice Rising Awards - Accessibility"/>
                        <meta itemProp="description" content="Open Society Foundations Justice Rising Awards Accessibility"/>
                        <meta itemProp="image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta property="og:url" content={window.location.href}/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content="Justice Rising Awards - Accessibility"/>
                        <meta property="og:description" content="Open Society Foundations Justice Rising Awards Accessibility"/>
                        <meta property="og:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:title" content="Justice Rising Awards - Accessibility"/>
                        <meta name="twitter:description" content="Open Society Foundations Justice Rising Awards Accessibility"/>
                        <meta name="twitter:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                    </Helmet>
                </HelmetProvider>
                <div id="accessibility-page-container" className="container">
                    <div className="header-xl" id="accessibility-page-header-title">
                        Digital Accessibility
                    </div>
                    <hr className="support-page-header-horizontal-rule"/>
                    <div id="contents-container">
                        <div id="contact-info-container">
                            <ContactInfoCard/>
                        </div>
                        <div id="accessibility-text-container">
                            <div className="header-l support-page-header-text">
                                
                            </div>
                            <div className="body-text support-page-body-text">
                                This website is owned and operated by the Open Society Foundations.
                            </div>
                            <div className="body-text support-page-body-text">
                                The Open Society Foundations have made digital accessibility a priority. We understand that people access and experience the web in different ways, and we are committed to providing a website that is easy and enjoyable to use for the widest, most diverse audience—including, but not limited to, people with disabilities or who rely on any kind of technology for a better experience. We developed our website with this inclusivity in mind.
                            </div>
                            <div className="body-text support-page-body-text">
                                Our website meets accessibility standards under Web Content Accessibility Guidelines (WCAG) 2.1, level AA.
                            </div>
                            <div className="body-text support-page-body-text">
                                These guidelines are organized under four important principles:
                            </div>
                            <div className="body-text support-page-body-text">
                                <ol>
                                    <li className="support-page-list-item"><div style={{fontWeight: 'bolder', display: 'inline'}}>Perceivable</div>—Information and user interface components must be presentable to users in ways they can perceive. We ensure, for example, that the contrast between text and its background meets the standard to allow those with certain visual impairments to be able to read our content. Images contain alt text, and captions or transcripts accompany multimedia so that users have alternatives to content they might be unable to consume.</li>
                                    <li className="support-page-list-item"><div style={{fontWeight: 'bolder', display: 'inline'}}>Operable</div>—User interface components and navigation must be operable. Visitors are able to navigate and perform actions using only keyboards, screen readers, or other assistive technology. Responsive design makes our site accessible across desktop browsers, mobile devices, and tablets.</li>
                                    <li className="support-page-list-item"><div style={{fontWeight: 'bolder', display: 'inline'}}>Understandable</div>—Information and operation of the user interface must be understandable. We use standard semantic elements throughout the site so that navigating it is straightforward, easy to understand, and predictable. Likewise, we avoid links, forms, or other interactions that automatically trigger disorienting changes to context.</li>
                                    <li className="support-page-list-item"><div style={{fontWeight: 'bolder', display: 'inline'}}>Robust</div>—Content must be robust enough that it can be interpreted reliably by a wide variety of user agents, including assistive technologies. Our underlying code allows visitors to effectively interact with dynamic features such as modal windows, drop-down menus, slideshows, or carousels.</li>
                                </ol>
                            </div>
                            <div className="body-text support-page-body-text">
                                Accessibility is part of our larger commitment to <a href="https://www.opensocietyfoundations.org/employment/our-commitment-to-diversity">diversity and inclusion</a> at the Open Society Foundations.
                            </div>
                            <div className="body-text support-page-body-text">
                                Please contact us if you have any questions or comments related to our accessibility efforts.
                            </div>
                            <div id="accessibility-page-footer-border"/>
                        </div>      
                    </div>
                </div>
            </>
        )
    }
}