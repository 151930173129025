import { Component } from 'react'
import { ContactInfoCard } from '../components/ContactInfoCard'
import { Helmet, HelmetProvider } from "react-helmet-async";
import './CookiePolicyPage.css'

export class CookiePolicyPage extends Component {

    render() {
        return(
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>Justice Rising Awards - Cookie Policy</title>
                        <meta itemProp="name" content="Justice Rising Awards - Cookie Policy"/>
                        <meta itemProp="description" content="Open Society Foundations Justice Rising Awards Cookie Policy"/>
                        <meta itemProp="image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta property="og:url" content={window.location.href}/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content="Justice Rising Awards - Cookie Policy"/>
                        <meta property="og:description" content="Open Society Foundations Justice Rising Awards Cookie Policy"/>
                        <meta property="og:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:title" content="Justice Rising Awards - Cookie Policy"/>
                        <meta name="twitter:description" content="Open Society Foundations Justice Rising Awards Cookie Policy"/>
                        <meta name="twitter:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                    </Helmet>
                </HelmetProvider>
                <div id="cookie-policy-page-container" className="container">
                    <div className="header-xl" id="toc-page-header-title">
                        Cookie Policy
                    </div>
                    <hr className="support-page-header-horizontal-rule"/>
                    <div id="contents-container">
                        <div id="contact-info-container">
                            <ContactInfoCard/>
                        </div>
                        <div id="cookie-policy-text-container">
                            <div className="body-text support-page-body-text">
                                This website is owned and operated by the Open Society Foundations.
                            </div>
                            <div className="body-text support-page-body-text">
                                This Cookie Policy (<div style={{fontWeight: 'bolder', display: 'inline'}}>Policy</div>) explains how the Open Society Foundations (<div style={{fontWeight: 'bolder', display: 'inline'}}>OSF</div>) uses cookies and other tracking technologies through our website <a href="https://www.opensocietyjusticerising.org">www.opensocietyjusticerising.org</a> and other sites owned and operated by us.
                            </div>
                            <div className="body-text support-page-body-text">
                                This Policy is to be read alongside OSF’s <a href="https://www.opensocietyfoundations.org/about/policies/privacy">Privacy Policy</a> which explains how OSF uses personal information. We only deploy non-essential cookies, including analytics cookies, on the basis of your consent.
                            </div>
                            <div className="body-text support-page-body-text">
                                <a href="/cookie-policy#manage">You can access the cookie settings information and update your preferences at any time here.</a>
                            </div>
                            <div className="header-l support-page-header-text">
                                What are web cookies?
                            </div>
                            <div className="body-text support-page-body-text">
                                Web cookies are small files that are placed on your computer or mobile device by a website when you visit it. They contain details of your browsing history on that website and distinguish you from other users. Cookies send data back to the originating website on each subsequent visit or allow another website to recognize the cookie. Cookies are useful because they allow a website to recognize a user’s device and, for instance, remember your preferences and generally improve your online user experience. Like most websites, OSF’s websites use cookies.
                            </div>
                            <div className="body-text support-page-body-text">
                                Although this Policy refers to the general term “cookie,” which is the main method used by our website to store information, the browser’s local storage space is also used for the same purpose and we may use other tracking technologies through this website such as web beacons, clear GIFs, or pixel tags. We usually use such tracking technologies to count users who visit certain pages of our website and to deliver branded services as well as to help determine the effectiveness of promotional campaigns.
                            </div>
                            <div className="body-text support-page-body-text">
                                As a result, the information included in this Policy is likewise applicable to all such tracking technologies that we use.
                            </div>
                            <div className="header-l support-page-header-text">
                                Why do we use cookies?
                            </div>
                            <div className="body-text support-page-body-text">
                                <ol>
                                    <li className="support-page-list-item">To <div style={{fontWeight: 'bolder', display: 'inline'}}>let you do things on our website</div>—for example, cookies enable you to log in to secure areas of our website, fill in application forms or view content.</li>
                                    <li className="support-page-list-item">To <div style={{fontWeight: 'bolder', display: 'inline'}}>collect anonymous statistics</div>—the information collected by cookies enables us to improve the website through use figures and patterns. For example, it is really useful to see which pages of the website—and therefore which of our services—are the most popular and how users are interacting with them.</li>
                                    <li className="support-page-list-item">To <div style={{fontWeight: 'bolder', display: 'inline'}}>improve your experience of our website</div>—for example, to prevent you having to re-enter details when you have already done so, or by ensuring that users can find what they are looking for easily.</li>
                                    <li className="support-page-list-item">To <div style={{fontWeight: 'bolder', display: 'inline'}}>ensure that our website is secure</div>—for example, to make sure that personal information that you give to us does not fall into the wrong hands.</li>
                                </ol>
                            </div>
                            <div className="header-l support-page-header-text">
                                Cookies we use
                            </div>
                            <div className="body-text support-page-body-text">
                                OSF uses both essential and non-essential cookies on our websites. We will only deploy non-essential cookies with your consent. When you first visit one of our websites, you will see a cookie consent banner that allows you to accept or reject the use of cookies. By clicking on “settings” in that banner, you can also view all the cookies deployed on that website.
                            </div>
                            <div className="body-text support-page-body-text">
                                In order to measure your interaction with our websites, with your consent we use Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the services and report on activities and trends. This service may also collect information regarding the use of other websites, apps and online resources.
                            </div>
                            <div className="header-l support-page-header-text">
                                Accepting and rejecting cookies
                            </div>
                            <div className="body-text support-page-body-text">
                                To consent to the use of cookies, click “accept” in the cookie consent banner. To reject the use of cookies, click “reject” in the cookie consent banner. All non-essential cookies are automatically set to “off.”
                            </div>
                            <div className="body-text support-page-body-text">
                                By clicking on “settings” in the cookie consent banner, you also can view a list of all cookies that may be deployed by the website and further information about each cookie, including its purpose.
                            </div>
                            <div className="body-text support-page-body-text">
                                You can access the cookie settings information and update your preferences at any time here.
                            </div>
                            <div className="body-text support-page-body-text">
                                You can use your browser settings to clear the cookies already stored on your device—this will clear the cookies previously stored on your device by this and other websites and you will be presented with the cookie consent banner again the next time you visit OSF’s websites.
                            </div>
                            <div className="header-l support-page-header-text">
                                Updating this Policy
                            </div>
                            <div className="body-text support-page-body-text">
                                We may update our use of cookies from time to time and consequently we may update this Policy. We therefore recommend that you check this Policy regularly. We will notify you of any significant changes by way of a notice on our website or by contacting you directly when reasonably possible.
                            </div>
                            <div id="cookie-policy-page-footer-border"/>
                        </div>      
                    </div>
                </div>
            </>
        )
    }
}