import React, { Component } from "react"
import "./GetInTouch.css"

export class GetInTouch extends Component {
    render() {
        return (
            <section id="get-in-touch">
                <div>
                    <h2 className="header-s">
                        GET IN TOUCH
                    </h2>
                    <div id="get-in-touch-content" className="support-text-l">
                        <div id="contact-information-text-container">
                            <div>Open Society Foundations</div>
                            <div>224 West 57th Street</div>
                            <div>New York, NY 10019</div>
                        </div>
                        <div id="external-contact-links">
                            <p>
                                <a href="tel:+12125480600">(212) 548-0600</a>
                            </p>
                            <p>
                                <a href="mailto:justicerising@opensocietyfoundations.org">justicerising@opensocietyfoundations.org</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}