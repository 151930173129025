import { Component } from 'react'
import { ContactInfoCard } from '../components/ContactInfoCard'
import { Helmet, HelmetProvider } from "react-helmet-async";
import './IntellectualPropertyPage.css'

export class IntellectualPropertyPage extends Component {

    render() {
        return(
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>Justice Rising Awards - Intellectual Property</title>
                        <meta itemProp="name" content="Justice Rising Awards - Intellectual Property"/>
                        <meta itemProp="description" content="Open Society Foundations Justice Rising Awards Intellectual Property"/>
                        <meta itemProp="image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta property="og:url" content={window.location.href}/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content="Justice Rising Awards - Intellectual Property"/>
                        <meta property="og:description" content="Open Society Foundations Justice Rising Awards Intellectual Property"/>
                        <meta property="og:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:title" content="Justice Rising Awards - Intellectual Property"/>
                        <meta name="twitter:description" content="Open Society Foundations Justice Rising Awards Intellectual Property"/>
                        <meta name="twitter:image" content={process.env.PUBLIC_URL + "/share-logo.png"}/>
                    </Helmet>
                </HelmetProvider>
                <div id="intellectual-property-page-container" className="container">
                    <div className="header-xl" id="toc-page-header-title">
                        Intellectual Property
                    </div>
                    <hr className="support-page-header-horizontal-rule"/>
                    <div id="contents-container">
                        <div id="contact-info-container">
                            <ContactInfoCard/>
                        </div>
                        <div id="intellectual-property-text-container">
                            <div className="body-text support-page-body-text">
                                Open Society Foundations (OSF) grants often result in tangible products, such as reports, papers, research, data sets, books, film or television documentaries, or radio programs, that are covered by various intellectual property regimes, such as copyright and patent (“Work Product”). In addition, OSF itself creates and commissions a substantial amount of Work Product. OSF believes that its mission is advanced when the work product we support and create is made broadly available to the public.
                            </div>
                            <div className="header-l support-page-header-text">
                                Creative Commons License
                            </div>
                            <div className="body-text support-page-body-text">
                                OSF’s policy is to ensure that both its own Work Product and that of its grantees furthers its mission and charitable purposes and that it benefits the public. To that end, OSF has granted a <a href="http://creativecommons.org/licenses/by-nc-nd/3.0/">Creative Commons Attribution-NonCommercial-NoDerivs 3.0</a> license to virtually all of its own Work Product. Under the terms of this license, anyone may copy and widely distribute OSF’s Work Product without charge for non-commercial purposes, provided that they do not alter it, that they credit the Open Society Foundations as the creator, and that they provide a link to OSF’s website. Our images are not governed by the Creative Commons license and may not be used without our written permission.
                            </div>
                            <div className="body-text support-page-body-text">
                                We believe that OSF’s mission is served when our grantees’ Work Product is also made widely available to the public, with appropriate protection of legitimate interests. To that end, when a grant will produce Work Product, OSF often includes a clause in its grant agreements, whereby our grantees agree to consider broadly licensing all Work Product created with OSF funds using a Creative Commons license and to inform OSF of their decision. 
                            </div>
                            <div className="body-text support-page-body-text">
                                OSF recognizes there may be circumstances where limited or delayed dissemination of grantee Work Product or limited access to data may be appropriate to protect legitimate interests of the grantee, other funders, or principal investigators or participants in research studies. Where OSF invites grantees to consider using a Creative Commons license for their Work Product, the grant letter provides grantees with the flexibility to choose not to grant any such license.
                            </div>
                            <div className="body-text support-page-body-text">
                                Though OSF believes that, in most cases, intellectual property rights should not limit or deny access to grantees’ Work Product, grantees remain free to determine their own policies on such matters. 
                            </div>
                            <div id="intellectual-property-page-footer-border"/>
                        </div>      
                    </div>
                </div>
            </>
        )
    }
}