import axios, {AxiosInstance, AxiosResponse} from 'axios';
import camelCaseKeys from "camelcase-keys";

declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {}
  }

export abstract class HttpClient {
    protected readonly instance: AxiosInstance;

    public constructor(baseURL: string) {
        this.instance = axios.create({ baseURL });
        this._initializeResponseInterceptor();
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    }; 

    private _handleResponse = (response: AxiosResponse) => {
        return {
            ...response,
            data: camelCaseKeys(response.data, {deep: true}),
        }
    }

    protected _handleError = (error: any) => Promise.reject(error);
}