import React, { Component, MouseEvent } from "react"
import { HeaderMobile } from "./HeaderMobile"

import jra_logo from "../assets/jra_logo.svg"
import "./Header.css"

interface HeaderProps {}

interface HeaderState {
    isMobileView: boolean
}

const MOBILE_SCREEN_WIDTH = 768

export class Header extends Component<HeaderProps, HeaderState> {
    state: HeaderState = {
        isMobileView: window.innerWidth <= MOBILE_SCREEN_WIDTH ? true : false
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkWindowChange)
    }

    render() {
        return (
            <>
            {this.state.isMobileView && <HeaderMobile/>}
            {!this.state.isMobileView &&
                <div id="header">
                    <div className="container--wider">
                        <nav id="main-nav" aria-label="primary">
                            <div id="header--wrapper">
                                <a href="/"><img id="jra-logo" src={jra_logo} alt="justice rising awards logo"/></a>
                            </div>
                            <div id="main-nav__links" className="nav-text">
                                <button className="button-link-undecorated" data-anchor-name="awardees" onClick={this.scrollToHandler}>Awardees</button>
                                <a className="button-link-undecorated" href="/faq">FAQ</a>
                            </div>
                        </nav>
                    </div>
                </div>
            }
            </>
        )
    }

    scrollToHandler = (event: MouseEvent<HTMLButtonElement>) => {
        const anchorName = event.currentTarget.getAttribute("data-anchor-name");
        if (anchorName && document.getElementById('home-page-container')) {
            const element = document.getElementById(anchorName);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
            }
        } else {
            window.location.href = "/#" + anchorName
        }
    }

    checkWindowChange = () => {
        if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
            this.setState({ isMobileView: true })
        } else {
            this.setState({ isMobileView: false })
        }
    }
}