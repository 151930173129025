import {HttpClient} from "./HttpClient";
import {FinalistModel} from "../models/FinalistModel";
import { IFinalistRespository } from "../respository/IFinalistRepository";
import { FinalistMediaModel } from "../models/FinalistMediaModel";

export class FinalistApi extends HttpClient implements IFinalistRespository {
    private static readonly DefaultBaseUrl = "https://cyborgmob.com/wp-json/wp/v2"
    private static classInstance?: FinalistApi;

    private constructor(baseUrl: string) {
        super(baseUrl);
    }

    public static getInstance() {
        if (this.classInstance) {
            return this.classInstance;
        } else if (process.env.REACT_APP_ENV_WORDPRESS_API_BASE_URL) {
            return this.classInstance = new FinalistApi(process.env.REACT_APP_ENV_WORDPRESS_API_BASE_URL);
        } else {
            return this.classInstance = new FinalistApi(this.DefaultBaseUrl);
        }
    }

    public getFinalists = () => this.instance.get<FinalistModel[]>('/finalists/?_fields=acf,id,slug&per_page=100');

    public getFinalist = (id: string) => this.instance.get<FinalistModel>(`/finalists/${id}`); 

    public getFinalistBySlug = (slug: string) => this.instance.get<FinalistModel[]>(`/finalists/?slug=${slug}&_fields=acf,id,slug`); 

    public getFinalistMedia = () => this.instance.get<FinalistMediaModel[]>(
        '/media/?_fields=id,alt_text,description,caption,media_details&per_page=100');
}