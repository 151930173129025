import React, { Component } from "react"

import "./About.css"

export class About extends Component {
    render() {
        return (
            <section id="about">
                <div className="container">
                    <div id="about-wrapper">
                        <p className="body-text paragraph-margin">
                            As part of a larger, long-term effort to empower Black communities, the Open Society Justice Rising Awards seeks to recognize and support individuals who play a critical role in the fight for racial justice.
                        </p>
                        <p className="body-text">
                            The Awards celebrate 16 leaders across the United States and its territories for their capacity to inspire, innovate, and mobilize people in the face of considerable odds, and their tireless commitment to civil rights. Open Society has granted each of these individuals an award with no strings attached, to use as they see fit, in recognition of the many professional and personal sacrifices involved in the demanding work of building movements. 
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}