import {HttpClient} from "./HttpClient";
import { AxiosRequestConfig } from "axios";

export class NewsletterApi extends HttpClient {
    private static readonly DefaultBaseUrl = "https://www2-dev.osfound.org/e35rlg8s";
    private bearerToken = "";
    private static classInstance?: NewsletterApi;

    private constructor(baseUrl: string, bearerToken: string) {
        super(baseUrl);
        this.bearerToken = bearerToken;
        this._initializeRequestInterceptor();
    }
    
    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(
          this._handleRequest,
          this._handleError,
        );
    };
    
    private _handleRequest = (config: AxiosRequestConfig) => {
        if (config && config.headers) {
            config.headers['Authorization'] = this.bearerToken;
            config.headers['Content-Type'] = "multipart/form-data";
        }
        return config;
    };


    public static getInstance() {
        let bearerToken = "";
        if (process.env.REACT_APP_ENV_NEWSLETTER_API_BEARER_TOKEN) {
            bearerToken = process.env.REACT_APP_ENV_NEWSLETTER_API_BEARER_TOKEN;
        }
        if (!this.classInstance && process.env.REACT_APP_ENV_NEWSLETTER_API_BASE_URL) {
            this.classInstance = new NewsletterApi(process.env.REACT_APP_ENV_NEWSLETTER_API_BASE_URL, bearerToken);
        } else {
            this.classInstance = new NewsletterApi(this.DefaultBaseUrl, bearerToken);
        }
        return this.classInstance;
    }

    public subscribe = (body: FormData) => this.instance.post('', body); 
}