export enum PathNames {
    Home = "/",
    Finalists = "/awardee",
    FrequentlyAskedQuestions = "/faq",
    TermsAndConditions = "/terms",
    CookiePolicy = "/cookie-policy",
    Accessibility = "/accessibility",
    PrivacyPolicy = "/privacy",
    IntellectualProperty = "/intellectual-property",
    About = "/about",
    Wildcard = "*"
}