import React, { Component } from "react"
import "./HowAwardsGiven.css"

export class HowAwardsGiven extends Component {
    render() {
        return (
            <div id="how-awards-given__wrapper">
                <div id="how-awards-given-anchor" className="section-anchor"/>
                <h2 id="how-awards-given__header" className="header-l">
                    Learn more about the awards
                </h2>
                <p className="body-text paragraph-margin">
                    The Open Society Justice Rising Awards recognize and support leaders working towards racial justice and equality in the Black community in the United States.
                </p>
                <p id="how-awards-given__faq-link" className="support-text-xl">
                    <a href="/faq">Read the frequently asked questions</a>
                </p>
            </div>
        )
    }
}