import { Component, MouseEvent } from "react"
import { FinalistApi } from '../api/FinalistApi';
import { FinalistModel } from '../models/FinalistModel';
import { FinalistCard } from "../ui-kit/FinalistCard";

import expandMore from "../assets/expand_more.svg"
import expandLess from "../assets/expand_less.svg"
import arrowUp from "../assets/arrow_up.svg"

import "./Finalists.css"
import { FinalistMediaModel } from "../models/FinalistMediaModel";

interface FinalistsProps {}
interface FinalistsState {
    error: null | Error;
    finalists: FinalistModel[];
    finalistMediaMap: Map<number, FinalistMediaModel>;
    isFinalistsLoaded: boolean;
    isFinalistMediaLoaded: boolean;
}

export class Finalists extends Component<FinalistsProps, FinalistsState> {
    constructor(props: FinalistsProps) {
        super(props);
        this.state = {
            error: null,
            finalists: [],
            finalistMediaMap: new Map<number, FinalistMediaModel>(),
            isFinalistsLoaded: false,
            isFinalistMediaLoaded: false,
        }
    };

    componentDidMount() {
        this.getFinalistData();
    }

    getFinalistData = async () => {
        const finalistApi = FinalistApi.getInstance();
        await finalistApi.getFinalists()
        .then((result) => {
            this.setState({
                finalists: result.data.sort((a, b) => a.slug.localeCompare(b.slug)),
                isFinalistsLoaded: true
            });
        },
        (error) => {
            console.log(error);
            this.setState({
                isFinalistsLoaded: true,
                error
            });
        });

        await finalistApi.getFinalistMedia()
        .then((result) => {
            let finalistMediaMap = this.createFinalistMediaMap(result.data);
            this.setState({
                finalistMediaMap: finalistMediaMap,
                isFinalistMediaLoaded: true
            });
        },
        (error) => {
            console.log(error);
            this.setState({
                isFinalistMediaLoaded: true,
                error
            });
        });
    }

    createFinalistMediaMap(finalistMedia : FinalistMediaModel[]) : Map<number, FinalistMediaModel> {
        let finalistMediaObject = new Map<number, FinalistMediaModel>();
        finalistMedia.forEach(fm => {
            finalistMediaObject.set(fm.id, fm);
        });
        return finalistMediaObject;
    }

    render() {
        return (
            <section id="finalists">
                <div id="awardees" className="section-anchor"/>
                <div id="finalists__container" className="container">
                    <h2 id="finalists__header" className="header-l">
                        Awardees
                    </h2>
                    <div id="finalist__display_wrapper">
                        <div id="finalists__menu">
                            <div id="finalists__menu--spinner" onClick={this.toggleMenu}>
                                <div id="finalists__menu--spinner-text" className="support-text-l">
                                    Select Awardee
                                </div>
                                <img id="finalists__menu--spinner-icon-more" src={expandMore} alt=""/>
                                <img id="finalists__menu--spinner-icon-less" className="__closed" src={expandLess} alt=""/>
                            </div>
                            <nav id="finalists__menu--nav" className="__closed">
                                <ul className="support-text-l">
                                    {this.state.isFinalistsLoaded && this.state.isFinalistMediaLoaded && this.state.finalists.map(finalist => (
                                        <li data-anchor-name={finalist.slug} key={finalist.id} onClick={this.scrollToHandler}>
                                            {finalist.acf.name}
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div id="finalists__cards">
                            {this.state.isFinalistsLoaded && this.state.isFinalistMediaLoaded && this.state.finalists.map(finalist => (
                                <FinalistCard 
                                    key={finalist.id} 
                                    finalist={finalist} 
                                    finalistMedia={this.state.finalistMediaMap.get(finalist.acf.image)}
                                />
                            ))}
                        </div>
                        <div id="finalists__back-to-top-button--container" className="support-text-xl">
                            <div id="finalists__back-to-top-button" data-anchor-name="awardees" onClick={this.scrollToHandlerDiv}>
                                <img src={arrowUp} alt="Back to top"/> 
                                <div id="finalists__back-to-top-button--text">
                                    Back to Top
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    scrollToTopHandler = (event: MouseEvent<HTMLDivElement>) => {
        window.scroll({behavior: "smooth", top: 0, left: 0})
    }

    scrollToHandlerDiv = (event: MouseEvent<HTMLDivElement>) => {
        const anchorName = event.currentTarget.getAttribute("data-anchor-name");
        if (anchorName) {
            const element = document.getElementById(anchorName);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
            }
        }
    }

    scrollToHandler = (event: MouseEvent<HTMLLIElement>) => {
        this.toggleMenu();
        const anchorName = event.currentTarget.getAttribute("data-anchor-name");
        if (anchorName) {
            const element = document.getElementById(anchorName);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
            }
        }
    }

    toggleMenu = () => {
        var spinner_menu = document.getElementById("finalists__menu--nav");
        spinner_menu?.classList.toggle("__closed");

        var spinner_icon_more = document.getElementById("finalists__menu--spinner-icon-more");
        var spinner_icon_less = document.getElementById("finalists__menu--spinner-icon-less");
        if (spinner_icon_more?.classList.contains("__closed")) {
            spinner_icon_more?.classList.toggle("__closed");
            spinner_icon_less?.classList.toggle("__closed");
        } else {
            spinner_icon_more?.classList.toggle("__closed");
            spinner_icon_less?.classList.toggle("__closed");
        }
    }
}