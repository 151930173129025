import { Component } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { HowAwardsGivenAndNewsletter } from './HowAwardsGivenAndNewsletter'
import { FinalistModel } from '../models/FinalistModel'
import { FinalistApi } from '../api/FinalistApi'
import expandMore from "../assets/expand_more.svg"
import expandLess from "../assets/expand_less.svg"
import "./FinalistInfoPage.css"

interface FinalistInfoPageProps {}
interface FinalistInfoPageState {
    slug: undefined | string;
    error: null | Error;
    isLoaded: boolean,
    finalists: FinalistModel[];
}

export class FinalistInfoPage extends Component<FinalistInfoPageProps, FinalistInfoPageState> {
    constructor(props: FinalistInfoPageProps) {
        super(props);
        this.state = {
            slug: undefined,
            error: null,
            isLoaded: false,
            finalists: [],
        }
    };

    componentDidMount() {
        const finalistApi = FinalistApi.getInstance();
        finalistApi.getFinalists()
        .then((result) => {
            this.setState({
                slug: window.location.pathname.split("/").pop(),
                finalists: result.data.sort((a, b) => a.slug.localeCompare(b.slug)),
                isLoaded: true,
            });
        },
        (error) => {
            console.log(error);
            this.setState({
                isLoaded: true,
                error
            });
            <Navigate to={'/#awardees'} replace/>
        });
    }

    render() {
        if (!this.state.isLoaded) {
            return <div className="loading"/>
        }
        if (this.state.error) {
            return <></>
        }
        const finalists = this.state.finalists;
        const finalistMenuList = this.state.finalists.filter(element => element.slug !== this.state.slug);
        const currentFinalist = this.state.finalists.find(finalist => finalist.slug === this.state.slug);
        const currentIndex = this.state.finalists.findIndex(finalist => finalist.slug === this.state.slug);
        const nextFinalist = finalists[(currentIndex + 1) % finalists.length];
        let prevIndex = currentIndex - 1;
        if (prevIndex < 0) {
            prevIndex = finalists.length + prevIndex;
        } 
        return(
            <>
                <div id="finalist-page">
                    <div className="container--wider">
                        <div id="finalist-page-menu--mobile-container">
                            <div id="finalist-page-menu--mobile" className="support-text-l">
                                <div id="finalist-page-menu__spinner" 
                                    onClick={this.toggleMenu}>
                                    {currentFinalist?.acf.name}
                                    <img id="finalists-page-menu--spinner-icon-more" src={expandMore} alt=""/>
                                    <img id="finalists-page-menu--spinner-icon-less" className="__closed" src={expandLess} alt=""/>
                                </div>
                                <a href={nextFinalist?.slug}
                                    className="finalist-page-menu-padding button-link-undecorated finalist-page-menu__button"
                                    data-current-finalist-index={currentIndex}>
                                    Next
                                </a>
                            </div>
                            <div id="finalist-page-menu__dropdown" className="support-text-l __closed">
                                <nav>
                                    <ul className="support-text-l">
                                        {finalistMenuList.map(finalist => (
                                            <li data-anchor-name={finalist.slug} key={finalist.id}>
                                                <a className="button-link-undecorated finalist-page-menu__link" href={finalist.slug}>
                                                    {finalist.acf.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div id="finalist-page-container">
                            <div id="finalist-page-menu" className="support-text-l">
                                <h1 className="header-s">Awardees</h1>
                                <nav>
                                    <ul className="support-text-l">
                                        {finalists.map(finalist => (
                                            <li data-anchor-name={finalist.slug} key={finalist.id}>
                                                {finalist.slug !== this.state.slug && 
                                                    <a className="button-link-undecorated finalist-page-menu__link" href={finalist.slug}>
                                                        {finalist.acf.name}
                                                    </a>
                                                }
                                                {finalist.slug === this.state.slug && 
                                                    <span className="finalist-page-menu__link selected">
                                                        {finalist.acf.name}
                                                    </span>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                            <div id="finalist-page-profile">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
                <HowAwardsGivenAndNewsletter/>
                { window.location.pathname === "/finalist" ? <Navigate to={'/#finalists'} replace={true}/> : null }
            </>
        )
    }

    toggleMenu = () => {
        var spinner_menu = document.getElementById("finalist-page-menu__dropdown");
        spinner_menu?.classList.toggle("__closed");

        var spinner_icon_more = document.getElementById("finalists-page-menu--spinner-icon-more");
        var spinner_icon_less = document.getElementById("finalists-page-menu--spinner-icon-less");
        if (spinner_icon_more?.classList.contains("__closed")) {
            spinner_icon_more?.classList.toggle("__closed");
            spinner_icon_less?.classList.toggle("__closed");
        } else {
            spinner_icon_more?.classList.toggle("__closed");
            spinner_icon_less?.classList.toggle("__closed");
        }
    }
}