import { useEffect } from "react";

import "./cookieconsent.css";
import "./cookieconsent.js";

export default function CookieConsent() {
    useEffect(() => {
        const cc = window.initCookieConsent();

        // attach settings dialog to management links
        var manage_settings = document.querySelectorAll("a[href='/cookie-policy#manage']");
        manage_settings.forEach(function (elem) {
            if (document.addEventListener) {
                elem.addEventListener('click', function (e) {
                    e.preventDefault();
                    cc.showSettings(0);
                });
            } else {
                elem.attachEvent('onclick', function (e) {
                    e.preventDefault();
                    cc.showSettings(0);
                });
            }
        });


        cc.run({
            autorun: true,
            delay: 0,
            current_lang: 'en',
            autoclear_cookies: true,
            autoload_css: false,
            cookie_expiration: 365,
            debug: false,
        
            onAccept: function (cookies) {
                if (cc.allowedCategory('analytics_cookies')) {
        
                    const uaid = 'G-PQZVNGC5XK';
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + uaid;
                    document.getElementsByTagName('head')[0].appendChild(script);
        
                    function gtag() { 
                        var dataLayer = window.dataLayer = window.dataLayer || [];
                        dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', uaid);
        
                }
            },
        
            languages: {
                en: {
                    consent_modal: {
                        title: "",
                        description: 'We use cookies and similar technologies to improve your experience of our website, to collect anonymous statistics, and to keep our site reliable and secure. You may deny the use of all nonessential cookies by clicking “Reject.” By clicking “Accept,” you consent to the use of cookies on this site. For more information, see our <a aria-label="cookie policy" class="cc-link" href="/cookie-policy/">cookie policy</a>.',
                        primary_btn: {
                            text: 'Accept',
                            role: 'accept_all'				//'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Reject',
                            role: 'accept_necessary'				//'settings' or 'accept_necessary'
                        },
                        settings_btn: {
                            text: 'Settings',
                            role: 'settings'				//'accept_selected' or 'accept_all'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie Settings',
                        save_settings_btn: "Save settings",
                        accept_all_btn: "Accept all",
                        cookie_table_headers: [
                            { col1: "Name" },
                            { col2: "Provider" },
                            { col3: "Purpose" },
                            { col4: "Duration" }
                        ],
                        blocks: [
                            {
                                title: "",
                                description: 'Cookies are small files that are placed on your computer or mobile device by a website when you visit it. They contain details of your browsing history on that website and distinguish you from other users. Cookies are useful because they allow a website to recognize a user’s device and, for instance, remember your preferences and generally improve your online user experience.<br><br>Because your privacy is important, you can choose not to allow some types of cookies. Click on the different category headings to find out more. Please note: blocking some types of cookies may impact your experience.'
                            }, {
                                title: "Essential cookies",
                                description: 'These cookies are necessary for our website to function properly. They cannot be disabled.',
                                toggle: {
                                    value: 'necessary_cookies',
                                    enabled: true,
                                    readonly: true
                                },
                                cookie_table: [
                                    {
                                        col1: 'cc_cookie',
                                        col2: 'Open Society Justice Rising Awards',
                                        col3: 'Used to identify cookie consent settings.',
                                        col4: '1 year'
                                    }
                                ]
                            }, {
                                title: "Analytics cookies",
                                description: 'These cookies collect information about how you use the website, which pages you visited, and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                                toggle: {
                                    value: 'analytics_cookies',
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '_ga',
                                        col2: 'Google',
                                        col3: 'Used to identify unique users.',
                                        col4: '2 years'
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'Google',
                                        col3: 'Used to distinguish users.',
                                        col4: '24 hours'
                                    },
                                    {
                                        col1: '_gat_gtag_*',
                                        col2: 'Google',
                                        col3: 'Used to specify Google Analytics account.',
                                        col4: '1 minute'
                                    },
                                ]
                            },
                            {
                                title: "",
                                description: "",
                            }
                        ]
                    }
                }
            }
        });

    }, []);

    return null;
}