import React, { Component } from "react"
import "./HeroFooter.css"

export class HeroFooter extends Component {
    render() {
        return (
            <section id="hero-footer">
                <div id="about" className="section-anchor"></div>
                <div className="container">
                    <div id="hero-footer-wrapper">
                        <h1 className="header-xl">
                            Celebrating Black Leadership in Racial Justice
                        </h1>
                    </div>
                </div>
            </section>
        )
    }
}