import React, { Component, MouseEvent } from 'react'

import menu_hamburger from "../assets/menu_black_18dp.svg"
import menu_close from "../assets/menu_close.svg"
import jra_logo from "../assets/jra_logo.svg"

import "./HeaderMobile.css"

interface HeaderMobileProps {}

interface HeaderMobileState {}

export class HeaderMobile extends Component<HeaderMobileProps, HeaderMobileState> {
    render() {
        return (
            <div id="header__mobile">
                <div id="header__mobile--wrapper">
                    <a href="/"><img id="jra-logo" src={jra_logo} alt="justice rising awards logo"/></a>
                    <img id="menu-hamburger" src={menu_hamburger} alt="" onClick={this.toggleMenu}/>
                    <div className="clear"/>
                </div>
                <nav id="header__mobile--menu" className="__closed">
                    <img id="header__mobile--menu-close" src={menu_close} alt="" onClick={this.toggleMenu}/>
                    <ul className="nav-text--mobile">
                        <li>
                            <button className="button-link-undecorated" data-anchor-name="awardees" onClick={this.scrollToHandler}>
                                Awardees
                            </button>
                        </li>
                        <li>
                            <a className="button-link-undecorated" href="/faq">
                                FAQ
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }

    scrollToHandler = (event: MouseEvent<HTMLButtonElement>) => {
        this.toggleMenu();
        const anchorName = event.currentTarget.getAttribute("data-anchor-name");
        if (anchorName && document.getElementById('home-page-container')) {
            const element = document.getElementById(anchorName);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
            }
        } else {
            window.location.href = "/#" + anchorName
        }
    }

    toggleMenu = () => {
        var hamburger_menu = document.getElementById("header__mobile--menu");
        if (hamburger_menu == null) {
            return;
        }
        hamburger_menu.classList.toggle("__closed");
    }
}