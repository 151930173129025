import React, { Component } from "react"
import osf_logo from "../assets/osf_logo.svg"
import "./SuperHeaderMobile.css"

export class SuperHeaderMobile extends Component {
    render() {
        return (
            <div id="super-header__mobile">
                <a href="https://www.opensocietyfoundations.org/">
                    <img id="osf-logo" src={osf_logo} alt="open society foundations logo"/>
                </a>
            </div>
        )
    }
}