import { HttpClient } from "./HttpClient";
import { HeroModel } from "../models/HeroModel";

export class HeroApi extends HttpClient {
    private static readonly DefaultBaseUrl = "https://cyborgmob.com/wp-json/wp/v2"
    private static classInstance?: HeroApi;

    private constructor(baseUrl: string) {
        super(baseUrl);
    }

    public static getInstance() {
        if (!this.classInstance && process.env.REACT_APP_ENV_WORDPRESS_API_BASE_URL) {
            this.classInstance = new HeroApi(process.env.REACT_APP_ENV_WORDPRESS_API_BASE_URL);
        } else {
            this.classInstance = new HeroApi(this.DefaultBaseUrl);
        }
        return this.classInstance;
    }

    public getHeros = () => this.instance.get<HeroModel[]>('/hero/?_fields=acf,id,image');
}