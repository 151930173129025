import React, { Component } from "react"
import { SuperHeaderMobile } from "./SuperHeaderMobile"

import osf_logo from "../assets/osf_logo.svg"
import "./SuperHeader.css"

interface SuperHeaderProps {}

interface SuperHeaderState {
    isMobileView: boolean
}

const MOBILE_SCREEN_WIDTH = 768

export class SuperHeader extends Component<SuperHeaderProps, SuperHeaderState> {
    state: SuperHeaderState = {
        isMobileView: window.innerWidth <= MOBILE_SCREEN_WIDTH ? true : false
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkWindowChange)
    }

    render() {
        return (
            <>
            {this.state.isMobileView && <SuperHeaderMobile/>}
            {!this.state.isMobileView &&
                <div id="super-header">
                    <div className="container--wider">
                        <a href="https://www.opensocietyfoundations.org/">
                            <img id="osf-logo" src={osf_logo} alt="open society foundations logo"/>
                        </a>
                    </div>
                </div>
            }
            </>
        )
    }

    checkWindowChange = () => {
        if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
            this.setState({ isMobileView: true })
        } else {
            this.setState({ isMobileView: false })
        }
    }
}