import { Component } from 'react'
import './ContactInfoCard.css'

export class ContactInfoCard extends Component {
    render() {
        return(
            <div id="contact-info-card-container">
                <div id="last-updated-info-container">
                    <div className="header-s" id="last-updated-header">
                        Last Updated
                    </div>
                    <div className="support-text-l" id="last-updated-date">
                        August 31, 2023
                    </div>
                </div>
                <div>
                    <div className="header-s" id="contact-info-header">
                        Contact
                    </div>
                    <div className="support-text-l" id="contact-information-text-container">
                        <div id="contact-address-recipient">Open Society Foundations</div>
                        <div>224 West 57th Street</div>
                        <div>New York, NY 10019</div>
                        <div>United States</div>
                        <div>P. +1-212-548-0600</div>
                        <div>F. +1-212-548-4600</div>
                    </div>
                </div>
            </div>
        )
    }
}