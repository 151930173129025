import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FinalistApi } from '../api/FinalistApi';
import { FinalistModel } from '../models/FinalistModel';
import { HeroVideo } from '../pages/HeroVideo';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FinalistMediaModel } from "../models/FinalistMediaModel";
import sanitizeHtml from "sanitize-html";

import "./FinalistInfo.css";
import { AwardeeShare } from './AwardeeShare';

export function FinalistInfo() {
    const [finalist, setFinalist] = useState<FinalistModel | null>(null);
    const [finalistMedia, setFinalistMedia] = useState<FinalistMediaModel | null>(null);
    const [isFinalistLoaded, setIsFinalistLoaded] = useState<boolean>(false);
    const [isMediaDetailsLoaded, setIsMediaDetailsLoaded] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    let params = useParams();

    useEffect(() => {
        const finalistApi = FinalistApi.getInstance();
        if(params.finalistName) {
            finalistApi.getFinalistBySlug(params.finalistName)
            .then((result) => {
                setFinalist(result.data[0]);
                return result.data[0].acf.image;
            },
            (error) => {
                console.log(error);
            })
            .then((imageId) => {
                if (imageId) {
                    setMediaDetailsFromImage(imageId);
                    setIsFinalistLoaded(true);
                }
            },
            (error) => {
                console.log(error);
                setError(error);
            });
        }
    }, [params.finalistName]);

    const setMediaDetailsFromImage = async (imageId: number) => {
        const finalistApi = FinalistApi.getInstance();
        await finalistApi.getFinalistMedia()
        .then((result) => {
            let finalistMediaModel = result.data.find(m => m.id === imageId);
            if (finalistMediaModel) {
                setFinalistMedia(finalistMediaModel);
                setIsMediaDetailsLoaded(true);
            }
        },
        (error) => {
            console.log(error);
            setError(error);
        });
    }

    if (!isFinalistLoaded || !isMediaDetailsLoaded) {
        return <div className="loading"/>
    }
    if (error) {
        return <></>
    }

    let bio = undefined;
    if (finalist?.acf.bio) {
        let cleanBio = sanitizeHtml(finalist.acf.bio, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a'],
            allowedAttributes: {
                'a': [ 'href' ]
            }
        });
        bio = {"__html": cleanBio}
    }

    let cleanPhotoCredit = undefined;
    if (finalistMedia?.description.rendered) {
        cleanPhotoCredit = sanitizeHtml(finalistMedia.description.rendered, {
            allowedTags: [],
            allowedAttributes: {}
        });
    }

    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{finalist?.acf.name} - Open Society Justice Rising Awards</title>
                    <meta itemProp="name" content={finalist?.acf.seoName}/>
                    <meta itemProp="description" content={finalist?.acf.seoDescription}/>
                    <meta itemProp="image" content={finalistMedia?.mediaDetails?.sizes.full.sourceUrl}/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={finalist?.acf.ogTitle}/>
                    <meta property="og:description" content={finalist?.acf.ogDescription}/>
                    <meta property="og:image" content={finalistMedia?.mediaDetails?.sizes.full.sourceUrl}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content={finalist?.acf.twitterTitle}/>
                    <meta name="twitter:description" content={finalist?.acf.twitterDescription}/>
                    <meta name="twitter:image" content={finalistMedia?.mediaDetails?.sizes.full.sourceUrl}/>
                </Helmet>
            </HelmetProvider>
            <div id="finalist-info" className="finalist-info-wrapper">
                <HeroVideo 
                    enabled={true}
                    url={finalist?.acf.video} 
                    thumbnailUrl={finalistMedia?.mediaDetails?.sizes.full.sourceUrl}
                    containerClass="finalist-info-video-wrapper"/>
                <div className="finalist-info-hero">
                    <h1 className="finalist-info-hero-name header-xl">{finalist?.acf.name}</h1>
                    <p className="finalist-info-hero-location header-s">{finalist?.acf.location}</p>
                    <p className="finalist-info-hero-title support-text-l">{finalist?.acf.title}</p>
                </div>
                <div className="finalist-info-bio">
                    {finalist?.acf.bio && 
                        <p className="body-text multiline" dangerouslySetInnerHTML={bio}/>
                    }
                </div>
                <div className="finalist-info-bio-footer">
                    <div className="finalist-photo-credit">
                        <h4 className="header-s">Photo Credit</h4>
                        <p className="support-text-m">
                            {cleanPhotoCredit}
                        </p>
                    </div>
                    <AwardeeShare/>
                </div>
            </div>
        </>
    )
}